.tnt-drawer-schedule {
  
  nz-input-number {
    width: 100%;
  }

  &__step {
    margin: 40px 0;

  }

  &__title {
    font-size: 15px;
    font-weight: 800;
  }

  &__subtitle {
    font-size: 13px;
    margin-bottom: 4px;
  }
  
  .ant-divider {
    margin: 6px 0 14px 0;
  }

  &__content {
    
    nz-select {
      width: 100%;
    }

    nz-date-picker {
      width: 100%;
    }

    nz-time-picker {
      width: 100%;
    }

    &__day-view {
      margin-top: 15px;

      button {
        font-size: 12px;
      }
    }

    &__end--after {
      nz-radio-group {
        label {
          margin-bottom: 20px;
        }
      }

      &__label {
        margin-top: 10px;
      }
    }
  }

  &__footer {
    button {
      width: 100%;
    }
  }
}