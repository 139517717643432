.tnt-pg-error {
    height: 100%;
    background-color: #fff;

    &__title {
        font-size: 80px;
        font-weight: 700;
        color: $success-color;
    }

    &__subtitle {
        font-size: 20px;
        font-weight: 700;
        color: $success-color;
    }

    &__text {
        font-size: 2.5rem;
        font-weight: 700;
        color: $danger-color;
    }

    &__image {
        height: 100%;
        @media screen and (min-width: 768px) {
            height: 100vh;
        }

        img {
            max-width: 100%;
            @media screen and (min-width: 768px) {
                position: absolute;
                bottom: 35px;
            }
        }
    }
}