.tnt-alert {
  &--on {
    color: $success-color;
  }

  &--off {
      color: $danger-color;
  }

  &__info {
      color: $danger-color;
      font-size: 13px;
      font-weight: 600;
  }
}