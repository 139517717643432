.tnt-pg-event-create-schedule {
    margin-bottom: 40px;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 44px;
        padding: 6px 11px;
    }

    &__header {
        &__title {
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        &__action {
            text-align: right;
        }
    }
}
