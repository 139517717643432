html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
  -ms-text-size-adjust: 100%;
  color:#39364f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: none;
}

h3 {
  font-weight: 700;
}

a {
  font-weight: 500 !important;
}
