.tnt-drawer-channel-to-event {

  &__channel-table {
    .ant-table {
      background-color: #f4f0f6;

      tr, tr:hover {
        background-color: #f4f0f6 !important;
      }

      td, td:hover {
        background-color: #f4f0f6 !important;
      }
    }
  }

  &__event-table {

    &__selected {
      background: #eee2fd;

      &:hover {
        background: #eee2fd;
      }

      tr, tr:hover {
        background: #eee2fd !important;
      }

      td, td:hover {
        background: #eee2fd !important;
      }
    }
  }
}