.tnt-drawer-move-booking {

  &__main-booking-table {
    .ant-table {
      background-color: #f4f0f6;

      tr, tr:hover {
        background-color: #f4f0f6 !important;
      }

      td, td:hover {
        background-color: #f4f0f6 !important;
      }
    }
  }

  &__event-detail {
    
    margin-bottom: 20px;
  }

  &__event-table {

    &__selected {
      background: #eee2fd;

      &:hover {
        background: #eee2fd;
      }

      tr, tr:hover {
        background: #eee2fd !important;
      }

      td, td:hover {
        background: #eee2fd !important;
      }
    }
  }

  &__channel-table {

    &__selected {
      background: #eee2fd;

      &:hover {
        background: #eee2fd;
      }

      tr, tr:hover {
        background: #eee2fd !important;
      }

      td, td:hover {
        background: #eee2fd !important;
      }
    }
  }

  &__summary {
    margin-top: 40px;
    margin-left: 20px;

    &__icon {
      font-size: 36px;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 40px;
      color: #b55fde;
    }

    &__from {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      text-align: center;
    }

    &__booking-table {
      margin-top: 20px;

      .ant-table {
        background-color: #f4f0f6;
  
        tr, tr:hover {
          background-color: #f4f0f6 !important;
        }
  
        td, td:hover {
          background-color: #f4f0f6 !important;
        }
      }
    }
  }

  &__result {
    margin-top: 40px;
    margin-left: 20px;

    &__icon {
      color: #b55fde;
      font-size: 98px;
      text-align: right;
      margin-right: 40px;
    }

    &__booking-table {
      margin-top: 20px;
    }
  }
}