.tnt-pg-password-forgot {
    &__header {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 20px;
    }

    &__sub-header {
        color: #B5B5C3;
        font-size: 1.35rem;
    }

    &__content {
        margin-top: 50px;

        &__button--cancel {
            color: #3699FF;
            background-color: #E1F0FF;
            border-color: transparent;
        }
    }
}
