.tnt-widget-drawer {
  margin-bottom: 30px;

  &__content {
    height: 100%;
  }

  &__footer {
    float: right;

    button {
      margin-right: 8px;
    }
  }
}