.tnt-button {
  &__secondary {
    color: #fff;
    border-color: $success-color;
    background-color: $success-color;

    &:hover {
      color: #fff;
      border-color: $success-color;
      background-color: $success-color;
      opacity: 0.8;
    }

    &:focus, &:active {
      color: #fff;
      border-color: $success-color;
      background-color: $success-color;
    }
  }

  &__primary--reverse {
    color: $primary-color;
    border-color: $primary-color;
    background-color: #fff;

    &:hover {
      color: #fff;
      border-color: $primary-color;
      background-color: $primary-color;
      opacity: 0.8;
    }

    &:active {
      color: #fff;
      border-color: $primary-color;
      background-color: $primary-color;
    }
  }
}