.tnt-pg-setting-export {

  &__drop-container {
    width: 400px;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
  }

  &__drap-list {
    width: 500px;
    max-width: 100%;
    border: solid 1px #ccc;
    min-height: 418px;
    display: block;
    background: white;
    border-radius: 4px;
    overflow: hidden;

    .ant-empty-image {
      margin-bottom: 0;
    }

    .ant-empty-description {
      opacity: 0.4;
    }

    &__title {
      margin-left: 2px;
      margin-bottom: 4px;
      font-weight: bold;
    }

    &__item {
      padding: 20px 10px;
      border-bottom: solid 1px #ccc;
      color: rgba(0, 0, 0, 0.87);
      cursor: move;
      background: white;
      font-size: 14px;

      :last-child {
        border: none;
      }

      :not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      &__subtitle {
        opacity: 0.6;
        font-size: 12px;
        margin-bottom: 2px;
      }
    }
  }
}