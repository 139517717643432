.tnt-widget-tab {
  nz-tabs-nav {
    margin-bottom: 40px !important;
  }

  &__badge {
    top: -5px;
    left: 20px;
  }

  &__header {
    &__btn {
      display: inline;

      .ant-btn {
        margin-left: 5px;
      }

      .ant-btn-primary {
        margin-left: 0;
      }

      i {
        margin-right: 5px;
      }

      nz-badge {
        top: -3px;
        left: -15px;
      }
    }
  }
}