.tnt-pg-finance {
    &__header {
        &__btn {
            float: right;
            display: inline;
            margin-bottom: 20px !important;

            button {
                margin-left: 5px;
            }

            nz-range-picker {
                top: 1px;
            }

            nz-select {
                min-width: 400px;
                margin-right: 5px;
            }

            nz-spin {
                display: inline-block;
                margin-right: 30px;
            }

            &__range-picker--selected {
                border-color: #6081f0;
            }
        }
    }
    
    &__card {
        margin-right: 20px;
        margin-bottom: 60px;
        cursor: unset;

        border: 0;
        border-radius: 6px;
        -webkit-box-shadow: 0 4px 4px #dbdae3;
        box-shadow: 0 4px 4px #dbdae3;

        .ant-card-body {
            min-height: 300px;
        }

        &__chart-container {
            position: absolute;
            top: 30px;
            left: 10px;
            right: 10px;
            bottom: 30px;
            margin-top: 40px;

            &__no-data {
                width: 100%;
                position: absolute;
                text-align: center;
                top: 60px;
            
                .ant-empty-description {
                    top: -30px;
                    position: relative;
                    opacity: 0.5;
                }
            }
        }
    }

    &__statistic {
        position: relative;
        max-width: 1200px;
        min-height: 200px;
        left: 50%;
        transform: translateX(-50%);

        &__chart-container {
            position: absolute;
            top: 0;
            left: 10px;
            right: 10px;
            bottom: 30px;
        }
    }
}