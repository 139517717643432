// Radio Group
.ant-radio-group {
  .ant-radio-button-wrapper {
    height: 44px;
    line-height: 42px;
  }
  .ant-radio-button-wrapper:not(:last-child) {
    margin-right: 12px;
  }
}

// Drawer
.ant-drawer-title {
  font-weight: 700;
}

// Input
.ant-input-prefix {
  opacity: 0.3;
  margin-right: 10px;
}

// Back To Top
.ant-back-top {
  right: 35px;
  bottom: 25px;
}

// Radio Button
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: unset;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: unset;
  border-color: #d9d9d9;
}

// Tooltip
.ant-tooltip {
  .ant-tooltip-inner {
    text-align: center;
    font-weight: 600;
  }
}

// Button
.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.45);
  background-color: unset;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: #fff;
  opacity: 0.6;
  border-color: #6081f0;
  background: #6081f0;
}

.ant-btn.ant-btn-lg {
  font-size: 14px
}

.ant-btn.ant-btn-lg.ant-btn-primary {
  font-weight: 600;
}

// Dropdown
.ant-dropdown-menu {
  .danger {
    color: $danger-color;
  }
}

// Tabs
.ant-tabs {
  a {
    font-size: 15px;
  }
}

// Badge
.badge--primary {
  nz-badge-sup {
    background-color: $primary-color;
  }
}

.badge--success {
  nz-badge-sup {
    background-color: $success-color;
  }
}

// DatePicker
.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
  display: none;
}

// Select
.ant-select-item-option {

  .ant-select-item-option__title {
    font-size: 14px;
  }

  .ant-select-item-option__subtitle {
    opacity: 0.6;
    font-size: 12px;

    i {
      margin-left: -2px;
      opacity: 0.5;
    }
  }
}