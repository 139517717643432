
ngx-charts-bar-vertical {
    .ngx-charts-outer {
        min-width: 400px !important;
    }
    
    ngx-charts-legend {
        min-width: 150px;
    
        > div:first-child {
            min-width: 150px !important;
        }
    }
}
