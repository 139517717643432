.tnt-widget-table {
  &__header {
    i {
      color: $primary-color;
      margin-right: 5px;
    }

    &__cross {
      text-align: right;
      span {
        cursor: pointer;
      }
    }
  }

  &__content {
    &__href {
      cursor: pointer;
    }
  }

  &__search-box {
    padding: 8px;

    input {
      width: 188px;
      margin-bottom: 8px;
      display: block;
    }

    button {
      width: 90px;
    }

    &__search-button {
      margin-right: 8px;
    }
  }

  &__row {
    &__child {
      background: #fffaf8;
      border-bottom: 5px solid transparent;
      background-clip: padding-box;
      cursor: pointer;

      .ant-table-cell {
        background: #fffaf8;
      }
    }

    &__disabled {
      opacity: 0.6;
      background: #e9e9e9;
      pointer-events: none;
      .ant-table-cell {
        background: #e9e9e9;
      }
    }
  }

  .ant-tag-has-color {
    margin-right: 0;
    min-width: 127px;
    text-align: center;
  }
}
