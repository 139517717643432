.tnt-widget-date {

  &__card {
    width: 105px;
    min-height: 124px;

    i {
      color: #919eec;
      position: absolute;
      top: -4px;
      font-size: 120px;
    }

    &__body {
      position: relative;
      top: 54px;
      color: #fff;
      line-height: 25px;
      text-align: center;

      &__day {
        font-size: 24px;
        font-weight: 700;
      }

      &__month {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
