.tnt-drawer-email {
  
  min-height: 480px;

  .ck-editor__editable_inline {
    min-height: 300px;
  }

  &__form {
    &__info {
      margin-bottom: 20px;

      &__text {
        top: 2px;
        position: inherit;
      }

      nz-switch {
        margin-left: 6px;
      }
    }
  }

  &__preview {
    &__alert {
      margin-bottom: 20px;
    }
    
    &__subject {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 20px;
    }
  }
}