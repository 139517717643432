.tnt-calendar {

  .ant-picker-calendar-header {
    // Hide year radio button
    .ant-radio-button-wrapper[nzvalue="year"] {
      display: none;
    }

    // Remove right margin from month radio button
    .ant-radio-button-wrapper[nzvalue="month"] {
      margin-right: 0;
    }
  }
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 44px;
    padding: 6px 11px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    .ant-badge-status {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    .ant-empty-description {
      font-size: 20px;
      opacity: 0.6;
    }
  }
}