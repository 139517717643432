.tnt-drawer-merge-booking {

  &__main-booking-table {
    .ant-table {
      background-color: #f4f0f6;

      tr, tr:hover {
        background-color: #f4f0f6 !important;
      }

      td, td:hover {
        background-color: #f4f0f6 !important;
      }
    }
  }

  &__tag-main {
    min-width: 80px !important;
    text-align: center !important;
  }
}