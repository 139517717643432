.tnt-drawer-booking {

  nz-date-picker {
    width: 100%;
  }

  nz-time-picker {
    width: 100%;
  }
  
  nz-input-number {
    width: 100%;
  }

  &__booking-form {
    nz-select {
      max-width: 208px;
    }
    .form-ticket-type {
      .ant-select-item-empty {
        display: none !important;
      }
    }
  }
}