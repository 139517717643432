.tnt-event-card {
    &--sm {
        &__title {
            color: #1e0a3c;
        }
    
        &__subtitle {
            opacity: 0.6;
            font-size: 12px;
        }
    }

    &--lg {
        &__title {
            color: #1e0a3c;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
        }
    
        &__subtitle {
            opacity: 0.6;
            font-size: 12px;
            margin-bottom: 2px;
        }
    
        &__alarm {
            font-size: 12px;
        }
    
        &__sold-ticket {
            &__label {
                min-width: 50px;
                display: inline;
            }
    
            &__progress {
                width: 100px;
                margin-left: 5px;
                display: inline-block;
            }
        }
    }
}
