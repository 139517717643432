.tnt-pg-setting-email {
  &__spinner {
    margin-top: 40px;
  }

  &__domain {

    margin-top: 20px;
    
    &__icon {
        i {
            margin-right: 5px;
        }
    }

    .ant-table {
      background-color: #f4f0f6;

      tr, tr:hover {
        background-color: #f4f0f6 !important;
      }

      td, td:hover {
        background-color: #f4f0f6 !important;
      }
    }
  }

  .ant-steps-item-active {
    cursor: pointer;
    .ant-steps-item-description {
      color: $primary-color !important;
    }
  }
}