.tnt-pg-event-create {
    height: 100%;
    background-color: #fff;
    margin-bottom: 40px;

    &__step {
        margin: 40px 0;

        &__icon {
            font-size: 38px;
            opacity: 0.15;
        }

        &__title {
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0.5px;
            margin-top: 6px;
            margin-left: -2px;
        }

        &__subtitle {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.5px;
            margin-top: 6px;
        }

        nz-date-picker {
            width: 100%;
        }

        nz-select {
            width: 100%;
        }

        nz-input-number {
            width: 100%;
        }

        nz-switch {
            margin-top: 4px;
            margin-right: 5px;
        }

        &__channel {
            &__remove {
                top: 5px;

                span {
                    color: #f80000;
                    cursor: pointer;
                }
            }
        }
    }

    &__channel-tag {
        margin-top: 5px;
        margin-right: 0;
        min-width: 127px;
        text-align: center;
    }

    hr {
        width: 100%;
        height: 1px;
        background-color: #eeedf2;
    }
}