.tnt-pg-login {
    &__header {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 3.25rem;
    }

    &__alert {
        .ant-alert {
            padding: 1.5rem 2rem;
            border-radius: 0.42rem;
            margin-bottom: 2.5rem;
        }
    }

    &__password-forgot {
        text-align: right;
        margin-top: -20px;
        margin-bottom: 10px;
    }
}
