.tnt-drawer-event-edit {

  &__title {
    font-size: 15px;
    font-weight: 800;
  }

  &__subtitle {
    font-size: 13px;
    margin-bottom: 4px;
  }

  &__button {
    &__generate {
      width: 100%;
    }
  }
  
  .ant-divider {
    margin: 6px 0 14px 0;
  }

  nz-date-picker {
    width: 100%;
  }

  nz-select {
    width: 100%;
  }

  nz-switch {
    margin-top: 4px;
    margin-right: 5px;
  }

  nz-input-number {
    width: 100%;
  }
}