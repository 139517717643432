.layout-default {
  min-height: 100vh !important;
  margin: 0;

  &__header {
    color: #fff;
    padding: 0px 14px;

    &__logo {
      cursor: pointer;
      margin-left: 5px;
      display: inline-block;

      img {
        height: 24px;
      }
    }

    &__menu {
      
      &__left {
        display: inline-block;

        .ant-menu-item {
          &:hover {
            background-color: #4a5067 !important;
          }
        }

        .ant-menu-item-selected {
          background-color: #4a5067 !important;
        }

        &__trigger {
          font-size: 18px;
          line-height: 48px;
          margin-right: 28px;
          margin-left: 6px;
          position: relative;
          top: 6px;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: #1890ff;
          }
        }
      }

      &__right {
        float: right;
        display: block;
        margin-top: 2px;

        nz-select {
          min-width: 235px;
          margin-right: 12px;
        }
      }
    }

    &__icon {
      margin: 0 8px;
      
      i {
        font-size: 20px;
      }
    }

    &__divider {
      height: 22px;
      margin-bottom: 4px;
      background: #fff;
    }

    &__user {
      cursor: pointer;

      i {
        margin-left: 8px;
      }
    }
  }

  &__sidebar {
    padding-left: 8px;
    
    .ant-menu-item {
      margin-top: 0;

      &:hover {
        background-color: #4a5067 !important;
      }

      label {
        cursor: pointer;
        margin-left: 8px !important;
      }
    }

    .ant-menu-item-selected {
      background-color: #4a5067 !important;
    }

    &__badge--lg {
      display: inline;

      nz-badge {
        top: 4px;
        left: 10px;
      }
    }

    &__badge--sm {
      display: inline;

      nz-badge {
        top: -5px;
        left: -5px;
      }
    }
  }

  &__inner {
    padding: 0 24px 24px;
  }

  &__content {
    padding: 32px 48px;
  }
}