.tnt-ellipsis-popover {
    max-width: 250px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    cursor: pointer;

    &__popover {
        max-width: 500px;
        white-space: break-spaces;
        text-align: left;
        
        button {
            width: 55px;
        }
    }

    &__no-content {
        opacity: 0;
        position: inherit;
        left: -18px;
        text-align: center;
        font-size: 12px;

        &:hover {
            opacity: 0.6;
        }
    }
}
