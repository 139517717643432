.tnt-pg-setting-alert {

  &__form {
    margin-bottom: 30px;

    &__item {
      width: 40%;

      &:last-child {
        width: 10%;
      }
    }
  }

  &__item {
    margin-bottom: 20px;
  }

  &__header {
    color: #1e0a3c;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &__subtitle {
    opacity: 0.6;
    font-size: 12px;
    margin-bottom: 2px;
  }
}