.tnt-pg-event-date-list {
    .ant-select-on-sale {
        width: 110px;
        color: #52c41a;
        background: #f6ffed;
        // border-color: #b7eb8f;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }

    .ant-select-off-sale {
        width: 110px;
        color: #3659e3;
        background: #f0f5ff;
        // border-color: #b8cdff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }

    .ant-select-cancelled {
        width: 110px;
        color: #ff4d4f;
        background: #fff2f0;
        // border-color: #ffccc7;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }

    &__btn--calendar {
        label {
            padding: 0px 22px;
        }
    }

    .event-visibility {
        nz-tag {
            width: 60px;
            text-align: center;
        }

        .ant-tag-has-color {
            width: 60px;
            min-width: 60px !important;
            font-weight: 700;
        }
    }
}