.tnt-progress-bar {
  
  &--inline {
    &__label {
      min-width: 50px;
      display: inline-block;
    }

    &__progress {
        width: 100px;
        display: inline-block;
    }
  }

  &--vertical {
    &__label {
      font-size: 13px;
      min-width: 50px;
      text-align: center;
    }

    &__progress {
        width: 100%;
        &__inner {
            width: 70%;
            margin: 0 auto;
        }
    }
  }
}