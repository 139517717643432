.tnt-confirmation-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1001;
  background: #fff;

  &__inner {
    text-align: right;
    padding: 24px 50px;

    &__button {
      margin-left: 16px;
    }
  }

  &__divider {
    margin: 0;
  }
}