.tnt-pg-setting-notification {

  &__item {
    margin-bottom: 20px;
  }

  &__header {
    color: #1e0a3c;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &__subtitle {
    opacity: 0.6;
    font-size: 12px;
    margin-bottom: 2px;
  }
}