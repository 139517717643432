.tnt-pg-booking-channel-detail {
    &__header {
        margin-bottom: 20px !important;
    }

    &__overview {

        &__title {
            color: #1e0a3c;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &__subtitle {
            opacity: 0.6;
            font-size: 12px;
            margin-bottom: 2px;
        }
    }

    &__action {
        &__button {
            border: 0 !important;

            &__dropdown {
                min-width: 200px;
                font-size: 14px;

                .ant-dropdown-menu-item {
                    padding: 10px 16px;
                }
            }
        }
    }
}