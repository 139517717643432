.layout-passport {
    height: 100%;
    
    &__content {

        &__left {
            color: #fff;
            text-align: center;
            padding-top: 60px;
            padding-bottom: 10px;
            background-image: url('/assets/images/bg/bg-1.jpg');

            @media screen and (min-width: 768px) {
                right: 20px;
                height: 100vh;
                padding-top: 160px;
            }

            &__header {
                margin-bottom: 40px;

                @media screen and (min-width: 768px) {
                    margin-bottom: 60px;
                }
            }
    
            &__sub-header {
                color: #fff;
                font-size: 1.35rem;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 8px;

                @media screen and (min-width: 768px) {
                    font-size: 2rem;
                }
            }
        }

        &__right {
            padding-top: 25px;
        }
    }

    &__footer {
        display: flex;
        align-items: center;

        bottom: 10px;
        font-size: 1.25rem;
        font-weight: 600;
        width: 100%;

        &__menu{
            position: absolute;
            bottom: 25px;
            transform: translateX(-50%);
            left: 49%;

            @media screen and (min-width: 768px) {
                left: 67.2%;
            }

            a:not(:first-child){
                margin-left: 2.5rem
            }
        }
    }
}