.tnt-empty {
    margin-top: 100px;

    .ant-empty-image {
        height: 200px;
    }

    .ant-empty-description {
        font-size: 20px;
        opacity: 0.6;
    }
}
