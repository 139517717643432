.tnt-drawer-domain {
  &__info {
    font-size: 13px;
  }

  .ant-input-group-addon {
    width: 50% !important;
    text-align: left !important;
  }

  .ant-input-disabled {
    color: rgb(24, 28, 50);
    background-color: rgb(250, 250, 250);
  }  
}