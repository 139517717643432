.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-success {
  color: $success-color;
}

.text-danger {
  color: $danger-color;
}

.text-warning {
  color: $warning-color;
}

.text-info {
  color: $info-color;
}

.bg-primary {
  background: $primary-color;
}

.bg-secondary {
  background: $secondary-color;
}

.bg-success {
  background: $success-color;
}

.bg-danger {
  background: $danger-color;
}

.bg-warning {
  background: $warning-color;
}

.bg-info {
  background: $info-color;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.inline-block {
  display: inline-block;
}
