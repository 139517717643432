// See Default Themes : https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// Import the official default less style file
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@font-family: Neue Plak, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

// Main Colors
@primary-color: #3659e3;

// Background color for `<body>`
@body-background: #f0f2f5;
@layout-body-background: #fff;
@text-color: #181C32;
@font-size-base: 14px;

// Alert
@alert-warning-bg-color: #EEE5FF;
@alert-warning-border-color: transparent;
@alert-message-color: #8950FC;

// Back To Top
@back-top-bg: @primary-color;
@back-top-hover-bg: @primary-color-hover;
