.tnt-pg-event-list {  

    &__sort {
        nz-select {
            width: 200px;
        }
    }
    
    .ant-space {
        margin-right: 20px;
        margin-bottom: 40px;
    }

    .ant-card {
        width: 300px;
        min-height: 212px;

        .ant-card-head-wrapper {
            align-items: flex-start;
        }

        .ant-card-head-title, .ant-card-meta-title {
            white-space: unset;
        }
    }

    img {
        opacity: 0.5;
    }
}