.tnt-dropdown {
  &__button {
    border: 0 !important;
  }
  
  &__content {
    min-width: 200px;
    font-size: 14px;

    .ant-dropdown-menu-item {
        padding: 10px 16px;
    }
  }

  &__no-empty-component {
    .ant-select-item-empty {
      display: none;
    }
  }
}