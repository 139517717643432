.tnt-widget-card {
  margin-bottom: 30px;

  &__header {
    margin-bottom: 30px;

    &__title {
      font-size: 26px;
      font-weight: 700;
      display: inline-block;
    }

    &__title--sm {
      font-size: 22px;
      font-weight: 700;
      display: inline-block;
    }

    &__breadcrumb {
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 5px;
      display: inline-block;
    }

    &__btn {
      float: right;
      display: inline;

      button {
          margin-left: 5px;
      }

      i {
        margin-right: 5px;
      }

      nz-badge {
        top: -3px;
        left: -15px;
      }
    }
  }

  &__header--sm {
    margin-bottom: 20px;

    &__title {
      font-size: 22px;
      font-weight: 700;
      display: inline-block;
    }

    &__btn {
      float: right;
      display: inline;
      button {
          margin-left: 5px;
      }
    }
  }

  &__actions {
    margin-top: 20px;
    margin-bottom: 20px;

    &__left {
      nz-date-picker {
        width: 100%;
      }
  
      nz-range-picker {
        min-width: 300px;
      }
    }

    &__right {
      text-align: right;
      
      button {
          margin-left: 5px;

          i {
            margin-right: 5px;
          }
      }
    }
  }

  &__filter {
    margin-bottom: 30px;
    
    nz-date-picker {
      width: 100%;
    }

    nz-range-picker {
      min-width: 300px;
    }
  }

  &__body {
    &__block {
      margin-bottom: 60px;
    }
  }
}