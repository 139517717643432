.tnt-pg_event-date-detail {

    nz-divider {
        margin-top: 0;
    }

    &__header {
        margin-bottom: 20px !important;
    }

    &__card {
        min-height: 200px;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: unset;

        border: 0;
        border-radius: 6px;
        -webkit-box-shadow: 0 4px 4px #dbdae3;
        box-shadow: 0 4px 4px #dbdae3;

        .ant-card-body {
            padding: 12px 24px;
        }

        .ant-card-head-title {
            font-size: 14px;
            font-weight: 700;
        }

        .ant-card-extra {
            font-size: 12px;
            font-weight: 700;
        }

        &__body {
            height: 100px;
            font-size: 13px;

            &__detail {
                .tnt-event-card--lg {
                    min-width: 250px !important;
                }
            }

            &__note {
                width: 100%;
                height: 110px;
                opacity: 0.6;
                font-size: 12px;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                cursor: pointer;
                
                &:hover {
                    color: #3659e3;
                }

                :first-child {
                    min-width: 150px;
                }
            }

            &__note--empty {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                
                i {
                    margin-right: 5px;
                }
            }

            &__sold {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                :first-child {
                    width: 100%;
                }
            }
        }

        nz-divider {
            margin-bottom: 10px;
        }
    }

    &__card--empty {
        .ant-empty-image {
            height: 20px;
        }
    }

    &__sold-ticket {
        &__label {
            min-width: 50px;
            display: inline;
        }

        &__progress {
            width: 100px;
            margin-left: 5px;
            display: inline-block;
        }
    }

    &__overview {

        &__title {
            color: #1e0a3c;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &__subtitle {
            opacity: 0.6;
            font-size: 12px;
            margin-bottom: 2px;
        }

        &__alarm {
            font-size: 12px;
        }
    }

    &__communication {
        &__drawer {
            .ant-tag {
                min-width: 90px;
                text-align: center;
            }

            nz-filter-trigger {
                width: 95%;
                justify-content: flex-end;
            }
        }

        &__no-data {
            img {
                width: 70px;
                opacity: 0.37;
            }
        }
    }

    &__date-update-modal {
        nz-date-picker {
            width: 100%;
        }

        nz-time-picker {
            width: 100%;
        }
    }
}