.ant-radio-group .ant-radio-button-wrapper {
  height: 44px;
  line-height: 42px;
}
.ant-radio-group .ant-radio-button-wrapper:not(:last-child) {
  margin-right: 12px;
}

.ant-drawer-title {
  font-weight: 700;
}

.ant-input-prefix {
  opacity: 0.3;
  margin-right: 10px;
}

.ant-back-top {
  right: 35px;
  bottom: 25px;
}

.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: unset;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: unset;
  border-color: #d9d9d9;
}

.ant-tooltip .ant-tooltip-inner {
  text-align: center;
  font-weight: 600;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.45);
  background-color: unset;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: #fff;
  opacity: 0.6;
  border-color: #6081f0;
  background: #6081f0;
}

.ant-btn.ant-btn-lg {
  font-size: 14px;
}

.ant-btn.ant-btn-lg.ant-btn-primary {
  font-weight: 600;
}

.ant-dropdown-menu .danger {
  color: #F64E60;
}

.ant-tabs a {
  font-size: 15px;
}

.badge--primary nz-badge-sup {
  background-color: #3659e3;
}

.badge--success nz-badge-sup {
  background-color: #1BC5BD;
}

.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
  display: none;
}

.ant-select-item-option .ant-select-item-option__title {
  font-size: 14px;
}
.ant-select-item-option .ant-select-item-option__subtitle {
  opacity: 0.6;
  font-size: 12px;
}
.ant-select-item-option .ant-select-item-option__subtitle i {
  margin-left: -2px;
  opacity: 0.5;
}

ngx-charts-bar-vertical .ngx-charts-outer {
  min-width: 400px !important;
}
ngx-charts-bar-vertical ngx-charts-legend {
  min-width: 150px;
}
ngx-charts-bar-vertical ngx-charts-legend > div:first-child {
  min-width: 150px !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
  -ms-text-size-adjust: 100%;
  color: #39364f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: none;
}

h3 {
  font-weight: 700;
}

a {
  font-weight: 500 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-primary {
  color: #3659e3;
}

.text-secondary {
  color: #6c757d;
}

.text-success {
  color: #1BC5BD;
}

.text-danger {
  color: #F64E60;
}

.text-warning {
  color: #faad14;
}

.text-info {
  color: #17a2b8;
}

.bg-primary {
  background: #3659e3;
}

.bg-secondary {
  background: #6c757d;
}

.bg-success {
  background: #1BC5BD;
}

.bg-danger {
  background: #F64E60;
}

.bg-warning {
  background: #faad14;
}

.bg-info {
  background: #17a2b8;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.inline-block {
  display: inline-block;
}

.layout-default {
  min-height: 100vh !important;
  margin: 0;
}
.layout-default__header {
  color: #fff;
  padding: 0px 14px;
}
.layout-default__header__logo {
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;
}
.layout-default__header__logo img {
  height: 24px;
}
.layout-default__header__menu__left {
  display: inline-block;
}
.layout-default__header__menu__left .ant-menu-item:hover {
  background-color: #4a5067 !important;
}
.layout-default__header__menu__left .ant-menu-item-selected {
  background-color: #4a5067 !important;
}
.layout-default__header__menu__left__trigger {
  font-size: 18px;
  line-height: 48px;
  margin-right: 28px;
  margin-left: 6px;
  position: relative;
  top: 6px;
  cursor: pointer;
  transition: color 0.3s;
}
.layout-default__header__menu__left__trigger:hover {
  color: #1890ff;
}
.layout-default__header__menu__right {
  float: right;
  display: block;
  margin-top: 2px;
}
.layout-default__header__menu__right nz-select {
  min-width: 235px;
  margin-right: 12px;
}
.layout-default__header__icon {
  margin: 0 8px;
}
.layout-default__header__icon i {
  font-size: 20px;
}
.layout-default__header__divider {
  height: 22px;
  margin-bottom: 4px;
  background: #fff;
}
.layout-default__header__user {
  cursor: pointer;
}
.layout-default__header__user i {
  margin-left: 8px;
}
.layout-default__sidebar {
  padding-left: 8px;
}
.layout-default__sidebar .ant-menu-item {
  margin-top: 0;
}
.layout-default__sidebar .ant-menu-item:hover {
  background-color: #4a5067 !important;
}
.layout-default__sidebar .ant-menu-item label {
  cursor: pointer;
  margin-left: 8px !important;
}
.layout-default__sidebar .ant-menu-item-selected {
  background-color: #4a5067 !important;
}
.layout-default__sidebar__badge--lg {
  display: inline;
}
.layout-default__sidebar__badge--lg nz-badge {
  top: 4px;
  left: 10px;
}
.layout-default__sidebar__badge--sm {
  display: inline;
}
.layout-default__sidebar__badge--sm nz-badge {
  top: -5px;
  left: -5px;
}
.layout-default__inner {
  padding: 0 24px 24px;
}
.layout-default__content {
  padding: 32px 48px;
}

.layout-passport {
  height: 100%;
}
.layout-passport__content__left {
  color: #fff;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 10px;
  background-image: url("/assets/images/bg/bg-1.jpg");
}
@media screen and (min-width: 768px) {
  .layout-passport__content__left {
    right: 20px;
    height: 100vh;
    padding-top: 160px;
  }
}
.layout-passport__content__left__header {
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .layout-passport__content__left__header {
    margin-bottom: 60px;
  }
}
.layout-passport__content__left__sub-header {
  color: #fff;
  font-size: 1.35rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .layout-passport__content__left__sub-header {
    font-size: 2rem;
  }
}
.layout-passport__content__right {
  padding-top: 25px;
}
.layout-passport__footer {
  display: flex;
  align-items: center;
  bottom: 10px;
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%;
}
.layout-passport__footer__menu {
  position: absolute;
  bottom: 25px;
  transform: translateX(-50%);
  left: 49%;
}
@media screen and (min-width: 768px) {
  .layout-passport__footer__menu {
    left: 67.2%;
  }
}
.layout-passport__footer__menu a:not(:first-child) {
  margin-left: 2.5rem;
}

#splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
}

#splash-screen img {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
}

#splash-screen.hidden {
  opacity: 0;
  visibility: hidden;
}

.splash-spinner {
  animation: rotate 2s linear infinite;
  margin-left: calc(100vw - 100%);
  width: 50px;
  height: 50px;
}

.splash-spinner .path {
  stroke: #5d78ff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.tnt-confirmation-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1001;
  background: #fff;
}
.tnt-confirmation-bar__inner {
  text-align: right;
  padding: 24px 50px;
}
.tnt-confirmation-bar__inner__button {
  margin-left: 16px;
}
.tnt-confirmation-bar__divider {
  margin: 0;
}

.tnt-widget-tab nz-tabs-nav {
  margin-bottom: 40px !important;
}
.tnt-widget-tab__badge {
  top: -5px;
  left: 20px;
}
.tnt-widget-tab__header__btn {
  display: inline;
}
.tnt-widget-tab__header__btn .ant-btn {
  margin-left: 5px;
}
.tnt-widget-tab__header__btn .ant-btn-primary {
  margin-left: 0;
}
.tnt-widget-tab__header__btn i {
  margin-right: 5px;
}
.tnt-widget-tab__header__btn nz-badge {
  top: -3px;
  left: -15px;
}

.tnt-widget-card {
  margin-bottom: 30px;
}
.tnt-widget-card__header {
  margin-bottom: 30px;
}
.tnt-widget-card__header__title {
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
}
.tnt-widget-card__header__title--sm {
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
}
.tnt-widget-card__header__breadcrumb {
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 5px;
  display: inline-block;
}
.tnt-widget-card__header__btn {
  float: right;
  display: inline;
}
.tnt-widget-card__header__btn button {
  margin-left: 5px;
}
.tnt-widget-card__header__btn i {
  margin-right: 5px;
}
.tnt-widget-card__header__btn nz-badge {
  top: -3px;
  left: -15px;
}
.tnt-widget-card__header--sm {
  margin-bottom: 20px;
}
.tnt-widget-card__header--sm__title {
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
}
.tnt-widget-card__header--sm__btn {
  float: right;
  display: inline;
}
.tnt-widget-card__header--sm__btn button {
  margin-left: 5px;
}
.tnt-widget-card__actions {
  margin-top: 20px;
  margin-bottom: 20px;
}
.tnt-widget-card__actions__left nz-date-picker {
  width: 100%;
}
.tnt-widget-card__actions__left nz-range-picker {
  min-width: 300px;
}
.tnt-widget-card__actions__right {
  text-align: right;
}
.tnt-widget-card__actions__right button {
  margin-left: 5px;
}
.tnt-widget-card__actions__right button i {
  margin-right: 5px;
}
.tnt-widget-card__filter {
  margin-bottom: 30px;
}
.tnt-widget-card__filter nz-date-picker {
  width: 100%;
}
.tnt-widget-card__filter nz-range-picker {
  min-width: 300px;
}
.tnt-widget-card__body__block {
  margin-bottom: 60px;
}

.tnt-widget-table__header i {
  color: #3659e3;
  margin-right: 5px;
}
.tnt-widget-table__header__cross {
  text-align: right;
}
.tnt-widget-table__header__cross span {
  cursor: pointer;
}
.tnt-widget-table__content__href {
  cursor: pointer;
}
.tnt-widget-table__search-box {
  padding: 8px;
}
.tnt-widget-table__search-box input {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}
.tnt-widget-table__search-box button {
  width: 90px;
}
.tnt-widget-table__search-box__search-button {
  margin-right: 8px;
}
.tnt-widget-table__row__child {
  background: #fffaf8;
  border-bottom: 5px solid transparent;
  background-clip: padding-box;
  cursor: pointer;
}
.tnt-widget-table__row__child .ant-table-cell {
  background: #fffaf8;
}
.tnt-widget-table__row__disabled {
  opacity: 0.6;
  background: #e9e9e9;
  pointer-events: none;
}
.tnt-widget-table__row__disabled .ant-table-cell {
  background: #e9e9e9;
}
.tnt-widget-table .ant-tag-has-color {
  margin-right: 0;
  min-width: 127px;
  text-align: center;
}

.tnt-widget-date__card {
  width: 105px;
  min-height: 124px;
}
.tnt-widget-date__card i {
  color: #919eec;
  position: absolute;
  top: -4px;
  font-size: 120px;
}
.tnt-widget-date__card__body {
  position: relative;
  top: 54px;
  color: #fff;
  line-height: 25px;
  text-align: center;
}
.tnt-widget-date__card__body__day {
  font-size: 24px;
  font-weight: 700;
}
.tnt-widget-date__card__body__month {
  font-size: 24px;
  font-weight: 700;
}

.tnt-widget-drawer {
  margin-bottom: 30px;
}
.tnt-widget-drawer__content {
  height: 100%;
}
.tnt-widget-drawer__footer {
  float: right;
}
.tnt-widget-drawer__footer button {
  margin-right: 8px;
}

.tnt-event-card--sm__title {
  color: #1e0a3c;
}
.tnt-event-card--sm__subtitle {
  opacity: 0.6;
  font-size: 12px;
}
.tnt-event-card--lg__title {
  color: #1e0a3c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.tnt-event-card--lg__subtitle {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 2px;
}
.tnt-event-card--lg__alarm {
  font-size: 12px;
}
.tnt-event-card--lg__sold-ticket__label {
  min-width: 50px;
  display: inline;
}
.tnt-event-card--lg__sold-ticket__progress {
  width: 100px;
  margin-left: 5px;
  display: inline-block;
}

.tnt-drawer-booking nz-date-picker {
  width: 100%;
}
.tnt-drawer-booking nz-time-picker {
  width: 100%;
}
.tnt-drawer-booking nz-input-number {
  width: 100%;
}
.tnt-drawer-booking__booking-form nz-select {
  max-width: 208px;
}
.tnt-drawer-booking__booking-form .form-ticket-type .ant-select-item-empty {
  display: none !important;
}

.tnt-drawer-move-booking__main-booking-table .ant-table {
  background-color: #f4f0f6;
}
.tnt-drawer-move-booking__main-booking-table .ant-table tr, .tnt-drawer-move-booking__main-booking-table .ant-table tr:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-move-booking__main-booking-table .ant-table td, .tnt-drawer-move-booking__main-booking-table .ant-table td:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-move-booking__event-detail {
  margin-bottom: 20px;
}
.tnt-drawer-move-booking__event-table__selected {
  background: #eee2fd;
}
.tnt-drawer-move-booking__event-table__selected:hover {
  background: #eee2fd;
}
.tnt-drawer-move-booking__event-table__selected tr, .tnt-drawer-move-booking__event-table__selected tr:hover {
  background: #eee2fd !important;
}
.tnt-drawer-move-booking__event-table__selected td, .tnt-drawer-move-booking__event-table__selected td:hover {
  background: #eee2fd !important;
}
.tnt-drawer-move-booking__channel-table__selected {
  background: #eee2fd;
}
.tnt-drawer-move-booking__channel-table__selected:hover {
  background: #eee2fd;
}
.tnt-drawer-move-booking__channel-table__selected tr, .tnt-drawer-move-booking__channel-table__selected tr:hover {
  background: #eee2fd !important;
}
.tnt-drawer-move-booking__channel-table__selected td, .tnt-drawer-move-booking__channel-table__selected td:hover {
  background: #eee2fd !important;
}
.tnt-drawer-move-booking__summary {
  margin-top: 40px;
  margin-left: 20px;
}
.tnt-drawer-move-booking__summary__icon {
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  color: #b55fde;
}
.tnt-drawer-move-booking__summary__from {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  text-align: center;
}
.tnt-drawer-move-booking__summary__booking-table {
  margin-top: 20px;
}
.tnt-drawer-move-booking__summary__booking-table .ant-table {
  background-color: #f4f0f6;
}
.tnt-drawer-move-booking__summary__booking-table .ant-table tr, .tnt-drawer-move-booking__summary__booking-table .ant-table tr:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-move-booking__summary__booking-table .ant-table td, .tnt-drawer-move-booking__summary__booking-table .ant-table td:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-move-booking__result {
  margin-top: 40px;
  margin-left: 20px;
}
.tnt-drawer-move-booking__result__icon {
  color: #b55fde;
  font-size: 98px;
  text-align: right;
  margin-right: 40px;
}
.tnt-drawer-move-booking__result__booking-table {
  margin-top: 20px;
}

.tnt-drawer-merge-booking__main-booking-table .ant-table {
  background-color: #f4f0f6;
}
.tnt-drawer-merge-booking__main-booking-table .ant-table tr, .tnt-drawer-merge-booking__main-booking-table .ant-table tr:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-merge-booking__main-booking-table .ant-table td, .tnt-drawer-merge-booking__main-booking-table .ant-table td:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-merge-booking__tag-main {
  min-width: 80px !important;
  text-align: center !important;
}

.tnt-drawer-channel-to-event__channel-table .ant-table {
  background-color: #f4f0f6;
}
.tnt-drawer-channel-to-event__channel-table .ant-table tr, .tnt-drawer-channel-to-event__channel-table .ant-table tr:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-channel-to-event__channel-table .ant-table td, .tnt-drawer-channel-to-event__channel-table .ant-table td:hover {
  background-color: #f4f0f6 !important;
}
.tnt-drawer-channel-to-event__event-table__selected {
  background: #eee2fd;
}
.tnt-drawer-channel-to-event__event-table__selected:hover {
  background: #eee2fd;
}
.tnt-drawer-channel-to-event__event-table__selected tr, .tnt-drawer-channel-to-event__event-table__selected tr:hover {
  background: #eee2fd !important;
}
.tnt-drawer-channel-to-event__event-table__selected td, .tnt-drawer-channel-to-event__event-table__selected td:hover {
  background: #eee2fd !important;
}

.tnt-drawer-schedule nz-input-number {
  width: 100%;
}
.tnt-drawer-schedule__step {
  margin: 40px 0;
}
.tnt-drawer-schedule__title {
  font-size: 15px;
  font-weight: 800;
}
.tnt-drawer-schedule__subtitle {
  font-size: 13px;
  margin-bottom: 4px;
}
.tnt-drawer-schedule .ant-divider {
  margin: 6px 0 14px 0;
}
.tnt-drawer-schedule__content nz-select {
  width: 100%;
}
.tnt-drawer-schedule__content nz-date-picker {
  width: 100%;
}
.tnt-drawer-schedule__content nz-time-picker {
  width: 100%;
}
.tnt-drawer-schedule__content__day-view {
  margin-top: 15px;
}
.tnt-drawer-schedule__content__day-view button {
  font-size: 12px;
}
.tnt-drawer-schedule__content__end--after nz-radio-group label {
  margin-bottom: 20px;
}
.tnt-drawer-schedule__content__end--after__label {
  margin-top: 10px;
}
.tnt-drawer-schedule__footer button {
  width: 100%;
}

.tnt-drawer-email {
  min-height: 480px;
}
.tnt-drawer-email .ck-editor__editable_inline {
  min-height: 300px;
}
.tnt-drawer-email__form__info {
  margin-bottom: 20px;
}
.tnt-drawer-email__form__info__text {
  top: 2px;
  position: inherit;
}
.tnt-drawer-email__form__info nz-switch {
  margin-left: 6px;
}
.tnt-drawer-email__preview__alert {
  margin-bottom: 20px;
}
.tnt-drawer-email__preview__subject {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
}

.tnt-drawer-event-edit__title {
  font-size: 15px;
  font-weight: 800;
}
.tnt-drawer-event-edit__subtitle {
  font-size: 13px;
  margin-bottom: 4px;
}
.tnt-drawer-event-edit__button__generate {
  width: 100%;
}
.tnt-drawer-event-edit .ant-divider {
  margin: 6px 0 14px 0;
}
.tnt-drawer-event-edit nz-date-picker {
  width: 100%;
}
.tnt-drawer-event-edit nz-select {
  width: 100%;
}
.tnt-drawer-event-edit nz-switch {
  margin-top: 4px;
  margin-right: 5px;
}
.tnt-drawer-event-edit nz-input-number {
  width: 100%;
}

.tnt-drawer-domain__info {
  font-size: 13px;
}
.tnt-drawer-domain .ant-input-group-addon {
  width: 50% !important;
  text-align: left !important;
}
.tnt-drawer-domain .ant-input-disabled {
  color: rgb(24, 28, 50);
  background-color: rgb(250, 250, 250);
}

.tnt-empty {
  margin-top: 100px;
}
.tnt-empty .ant-empty-image {
  height: 200px;
}
.tnt-empty .ant-empty-description {
  font-size: 20px;
  opacity: 0.6;
}

.tnt-progress-bar--inline__label {
  min-width: 50px;
  display: inline-block;
}
.tnt-progress-bar--inline__progress {
  width: 100px;
  display: inline-block;
}
.tnt-progress-bar--vertical__label {
  font-size: 13px;
  min-width: 50px;
  text-align: center;
}
.tnt-progress-bar--vertical__progress {
  width: 100%;
}
.tnt-progress-bar--vertical__progress__inner {
  width: 70%;
  margin: 0 auto;
}

.tnt-button__secondary {
  color: #fff;
  border-color: #1BC5BD;
  background-color: #1BC5BD;
}
.tnt-button__secondary:hover {
  color: #fff;
  border-color: #1BC5BD;
  background-color: #1BC5BD;
  opacity: 0.8;
}
.tnt-button__secondary:focus, .tnt-button__secondary:active {
  color: #fff;
  border-color: #1BC5BD;
  background-color: #1BC5BD;
}
.tnt-button__primary--reverse {
  color: #3659e3;
  border-color: #3659e3;
  background-color: #fff;
}
.tnt-button__primary--reverse:hover {
  color: #fff;
  border-color: #3659e3;
  background-color: #3659e3;
  opacity: 0.8;
}
.tnt-button__primary--reverse:active {
  color: #fff;
  border-color: #3659e3;
  background-color: #3659e3;
}

.tnt-ellipsis-popover {
  max-width: 250px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
}
.tnt-ellipsis-popover__popover {
  max-width: 500px;
  white-space: break-spaces;
  text-align: left;
}
.tnt-ellipsis-popover__popover button {
  width: 55px;
}
.tnt-ellipsis-popover__no-content {
  opacity: 0;
  position: inherit;
  left: -18px;
  text-align: center;
  font-size: 12px;
}
.tnt-ellipsis-popover__no-content:hover {
  opacity: 0.6;
}

.tnt-alert--on {
  color: #1BC5BD;
}
.tnt-alert--off {
  color: #F64E60;
}
.tnt-alert__info {
  color: #F64E60;
  font-size: 13px;
  font-weight: 600;
}

.tnt-calendar .ant-picker-calendar-header .ant-radio-button-wrapper[nzvalue=year] {
  display: none;
}
.tnt-calendar .ant-picker-calendar-header .ant-radio-button-wrapper[nzvalue=month] {
  margin-right: 0;
}
.tnt-calendar .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 44px;
  padding: 6px 11px;
}
.tnt-calendar__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tnt-calendar__list .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.tnt-calendar__list .ant-empty-description {
  font-size: 20px;
  opacity: 0.6;
}

.tnt-dropdown__button {
  border: 0 !important;
}
.tnt-dropdown__content {
  min-width: 200px;
  font-size: 14px;
}
.tnt-dropdown__content .ant-dropdown-menu-item {
  padding: 10px 16px;
}
.tnt-dropdown__no-empty-component .ant-select-item-empty {
  display: none;
}

.tnt-pg-login__header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3.25rem;
}
.tnt-pg-login__alert .ant-alert {
  padding: 1.5rem 2rem;
  border-radius: 0.42rem;
  margin-bottom: 2.5rem;
}
.tnt-pg-login__password-forgot {
  text-align: right;
  margin-top: -20px;
  margin-bottom: 10px;
}

.tnt-pg-error {
  height: 100%;
  background-color: #fff;
}
.tnt-pg-error__title {
  font-size: 80px;
  font-weight: 700;
  color: #1BC5BD;
}
.tnt-pg-error__subtitle {
  font-size: 20px;
  font-weight: 700;
  color: #1BC5BD;
}
.tnt-pg-error__text {
  font-size: 2.5rem;
  font-weight: 700;
  color: #F64E60;
}
.tnt-pg-error__image {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .tnt-pg-error__image {
    height: 100vh;
  }
}
.tnt-pg-error__image img {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .tnt-pg-error__image img {
    position: absolute;
    bottom: 35px;
  }
}

.tnt-pg-event-list__sort nz-select {
  width: 200px;
}
.tnt-pg-event-list .ant-space {
  margin-right: 20px;
  margin-bottom: 40px;
}
.tnt-pg-event-list .ant-card {
  width: 300px;
  min-height: 212px;
}
.tnt-pg-event-list .ant-card .ant-card-head-wrapper {
  align-items: flex-start;
}
.tnt-pg-event-list .ant-card .ant-card-head-title, .tnt-pg-event-list .ant-card .ant-card-meta-title {
  white-space: unset;
}
.tnt-pg-event-list img {
  opacity: 0.5;
}

.tnt-pg-event-detail .ant-select-on-sale {
  width: 110px;
  color: #3659e3;
  background: #f0f5ff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.tnt-pg-event-detail .ant-select-off-sale {
  width: 110px;
  color: #52c41a;
  background: #f6ffed;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.tnt-pg-event-detail .ant-select-cancelled {
  width: 110px;
  color: #ff4d4f;
  background: #fff2f0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.tnt-pg-event-detail__image {
  opacity: 0.8;
  width: 100px;
}
.tnt-pg-event-detail .event-visibility nz-tag {
  width: 60px;
  text-align: center;
}
.tnt-pg-event-detail .event-visibility .ant-tag-has-color {
  width: 60px;
  min-width: 60px !important;
  font-weight: 700;
}

.tnt-pg-event-date-list .ant-select-on-sale {
  width: 110px;
  color: #52c41a;
  background: #f6ffed;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.tnt-pg-event-date-list .ant-select-off-sale {
  width: 110px;
  color: #3659e3;
  background: #f0f5ff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.tnt-pg-event-date-list .ant-select-cancelled {
  width: 110px;
  color: #ff4d4f;
  background: #fff2f0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.tnt-pg-event-date-list__btn--calendar label {
  padding: 0px 22px;
}
.tnt-pg-event-date-list .event-visibility nz-tag {
  width: 60px;
  text-align: center;
}
.tnt-pg-event-date-list .event-visibility .ant-tag-has-color {
  width: 60px;
  min-width: 60px !important;
  font-weight: 700;
}

.tnt-pg_event-date-detail nz-divider {
  margin-top: 0;
}
.tnt-pg_event-date-detail__header {
  margin-bottom: 20px !important;
}
.tnt-pg_event-date-detail__card {
  min-height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: unset;
  border: 0;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 4px #dbdae3;
  box-shadow: 0 4px 4px #dbdae3;
}
.tnt-pg_event-date-detail__card .ant-card-body {
  padding: 12px 24px;
}
.tnt-pg_event-date-detail__card .ant-card-head-title {
  font-size: 14px;
  font-weight: 700;
}
.tnt-pg_event-date-detail__card .ant-card-extra {
  font-size: 12px;
  font-weight: 700;
}
.tnt-pg_event-date-detail__card__body {
  height: 100px;
  font-size: 13px;
}
.tnt-pg_event-date-detail__card__body__detail .tnt-event-card--lg {
  min-width: 250px !important;
}
.tnt-pg_event-date-detail__card__body__note {
  width: 100%;
  height: 110px;
  opacity: 0.6;
  font-size: 12px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
}
.tnt-pg_event-date-detail__card__body__note:hover {
  color: #3659e3;
}
.tnt-pg_event-date-detail__card__body__note :first-child {
  min-width: 150px;
}
.tnt-pg_event-date-detail__card__body__note--empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tnt-pg_event-date-detail__card__body__note--empty i {
  margin-right: 5px;
}
.tnt-pg_event-date-detail__card__body__sold {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tnt-pg_event-date-detail__card__body__sold :first-child {
  width: 100%;
}
.tnt-pg_event-date-detail__card nz-divider {
  margin-bottom: 10px;
}
.tnt-pg_event-date-detail__card--empty .ant-empty-image {
  height: 20px;
}
.tnt-pg_event-date-detail__sold-ticket__label {
  min-width: 50px;
  display: inline;
}
.tnt-pg_event-date-detail__sold-ticket__progress {
  width: 100px;
  margin-left: 5px;
  display: inline-block;
}
.tnt-pg_event-date-detail__overview__title {
  color: #1e0a3c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.tnt-pg_event-date-detail__overview__subtitle {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 2px;
}
.tnt-pg_event-date-detail__overview__alarm {
  font-size: 12px;
}
.tnt-pg_event-date-detail__communication__drawer .ant-tag {
  min-width: 90px;
  text-align: center;
}
.tnt-pg_event-date-detail__communication__drawer nz-filter-trigger {
  width: 95%;
  justify-content: flex-end;
}
.tnt-pg_event-date-detail__communication__no-data img {
  width: 70px;
  opacity: 0.37;
}
.tnt-pg_event-date-detail__date-update-modal nz-date-picker {
  width: 100%;
}
.tnt-pg_event-date-detail__date-update-modal nz-time-picker {
  width: 100%;
}

.tnt-pg-event-create {
  height: 100%;
  background-color: #fff;
  margin-bottom: 40px;
}
.tnt-pg-event-create__step {
  margin: 40px 0;
}
.tnt-pg-event-create__step__icon {
  font-size: 38px;
  opacity: 0.15;
}
.tnt-pg-event-create__step__title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 6px;
  margin-left: -2px;
}
.tnt-pg-event-create__step__subtitle {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 6px;
}
.tnt-pg-event-create__step nz-date-picker {
  width: 100%;
}
.tnt-pg-event-create__step nz-select {
  width: 100%;
}
.tnt-pg-event-create__step nz-input-number {
  width: 100%;
}
.tnt-pg-event-create__step nz-switch {
  margin-top: 4px;
  margin-right: 5px;
}
.tnt-pg-event-create__step__channel__remove {
  top: 5px;
}
.tnt-pg-event-create__step__channel__remove span {
  color: #f80000;
  cursor: pointer;
}
.tnt-pg-event-create__channel-tag {
  margin-top: 5px;
  margin-right: 0;
  min-width: 127px;
  text-align: center;
}
.tnt-pg-event-create hr {
  width: 100%;
  height: 1px;
  background-color: #eeedf2;
}

.tnt-pg-event-create-schedule {
  margin-bottom: 40px;
}
.tnt-pg-event-create-schedule .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 44px;
  padding: 6px 11px;
}
.tnt-pg-event-create-schedule__header__title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.tnt-pg-event-create-schedule__header__action {
  text-align: right;
}

.tnt-pg-password-forgot__header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.tnt-pg-password-forgot__sub-header {
  color: #B5B5C3;
  font-size: 1.35rem;
}
.tnt-pg-password-forgot__content {
  margin-top: 50px;
}
.tnt-pg-password-forgot__content__button--cancel {
  color: #3699FF;
  background-color: #E1F0FF;
  border-color: transparent;
}

.tnt-pg-booking-search__search-form nz-form-item {
  min-width: 400px;
  margin-right: 8px !important;
}

.tnt-pg-setting-email__spinner {
  margin-top: 40px;
}
.tnt-pg-setting-email__domain {
  margin-top: 20px;
}
.tnt-pg-setting-email__domain__icon i {
  margin-right: 5px;
}
.tnt-pg-setting-email__domain .ant-table {
  background-color: #f4f0f6;
}
.tnt-pg-setting-email__domain .ant-table tr, .tnt-pg-setting-email__domain .ant-table tr:hover {
  background-color: #f4f0f6 !important;
}
.tnt-pg-setting-email__domain .ant-table td, .tnt-pg-setting-email__domain .ant-table td:hover {
  background-color: #f4f0f6 !important;
}
.tnt-pg-setting-email .ant-steps-item-active {
  cursor: pointer;
}
.tnt-pg-setting-email .ant-steps-item-active .ant-steps-item-description {
  color: #3659e3 !important;
}

.tnt-pg-setting-profile button {
  min-width: 100px;
}

.tnt-pg-setting-notification__item {
  margin-bottom: 20px;
}
.tnt-pg-setting-notification__header {
  color: #1e0a3c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.tnt-pg-setting-notification__subtitle {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 2px;
}

.tnt-pg-setting-alert__form {
  margin-bottom: 30px;
}
.tnt-pg-setting-alert__form__item {
  width: 40%;
}
.tnt-pg-setting-alert__form__item:last-child {
  width: 10%;
}
.tnt-pg-setting-alert__item {
  margin-bottom: 20px;
}
.tnt-pg-setting-alert__header {
  color: #1e0a3c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.tnt-pg-setting-alert__subtitle {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 2px;
}

.tnt-pg-setting-export__drop-container {
  width: 400px;
  max-width: 100%;
  margin: 0 25px 25px 0;
  display: inline-block;
  vertical-align: top;
}
.tnt-pg-setting-export__drap-list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 418px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}
.tnt-pg-setting-export__drap-list .ant-empty-image {
  margin-bottom: 0;
}
.tnt-pg-setting-export__drap-list .ant-empty-description {
  opacity: 0.4;
}
.tnt-pg-setting-export__drap-list__title {
  margin-left: 2px;
  margin-bottom: 4px;
  font-weight: bold;
}
.tnt-pg-setting-export__drap-list__item {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  cursor: move;
  background: white;
  font-size: 14px;
}
.tnt-pg-setting-export__drap-list__item :last-child {
  border: none;
}
.tnt-pg-setting-export__drap-list__item :not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.tnt-pg-setting-export__drap-list__item__subtitle {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 2px;
}

.tnt-pg-setting-import__upload-area {
  height: 55vh;
}

.tnt-pg-booking-channel-detail__header {
  margin-bottom: 20px !important;
}
.tnt-pg-booking-channel-detail__overview__title {
  color: #1e0a3c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.tnt-pg-booking-channel-detail__overview__subtitle {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 2px;
}
.tnt-pg-booking-channel-detail__action__button {
  border: 0 !important;
}
.tnt-pg-booking-channel-detail__action__button__dropdown {
  min-width: 200px;
  font-size: 14px;
}
.tnt-pg-booking-channel-detail__action__button__dropdown .ant-dropdown-menu-item {
  padding: 10px 16px;
}

.tnt-pg-finance__header__btn {
  float: right;
  display: inline;
  margin-bottom: 20px !important;
}
.tnt-pg-finance__header__btn button {
  margin-left: 5px;
}
.tnt-pg-finance__header__btn nz-range-picker {
  top: 1px;
}
.tnt-pg-finance__header__btn nz-select {
  min-width: 400px;
  margin-right: 5px;
}
.tnt-pg-finance__header__btn nz-spin {
  display: inline-block;
  margin-right: 30px;
}
.tnt-pg-finance__header__btn__range-picker--selected {
  border-color: #6081f0;
}
.tnt-pg-finance__card {
  margin-right: 20px;
  margin-bottom: 60px;
  cursor: unset;
  border: 0;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 4px #dbdae3;
  box-shadow: 0 4px 4px #dbdae3;
}
.tnt-pg-finance__card .ant-card-body {
  min-height: 300px;
}
.tnt-pg-finance__card__chart-container {
  position: absolute;
  top: 30px;
  left: 10px;
  right: 10px;
  bottom: 30px;
  margin-top: 40px;
}
.tnt-pg-finance__card__chart-container__no-data {
  width: 100%;
  position: absolute;
  text-align: center;
  top: 60px;
}
.tnt-pg-finance__card__chart-container__no-data .ant-empty-description {
  top: -30px;
  position: relative;
  opacity: 0.5;
}
.tnt-pg-finance__statistic {
  position: relative;
  max-width: 1200px;
  min-height: 200px;
  left: 50%;
  transform: translateX(-50%);
}
.tnt-pg-finance__statistic__chart-container {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  bottom: 30px;
}